import {pulseAnimation} from '@/styles/animations/pulse.js'
import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'
import styled from 'styled-components'

export const UIAboutWrapper = styled.article`
  display: flex;
  background-color: var(--op-c-white);
  border-radius: 0.5rem;
  overflow: hidden;
`

export const UIAboutLeft = styled.aside`
  display: none;

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    display: block;
    flex: 1;
    max-width: 250px;
  }
`

export const UIAboutRight = styled.section`
  display: flex;
  flex-direction: column;
  background-color: var(--op-c-white);
  padding: calc(var(--op-spacing-base) * 8);
  flex: 1;

  h3 {
    font-size: var(--op-fz-h5);
    line-height: var(--op-lh-xxl);
    margin: 0;
  }

  h2 {
    font-size: var(--op-fz-h2);
    line-height: var(--op-lh-xxl);
    margin: 0;
    color: var(--op-c-secondary);
    animation-name: ${pulseAnimation('--op-c-secondary')};
    animation-duration: 1s;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    border-radius: 0.5rem;
  }
`

import NextImage from 'next/image'
import Section from '@/components/common/Section/index.js'
import {UIAboutWrapper, UIAboutLeft, UIAboutRight} from './styles/ui.js'

export default function About() {
  return (
    <Section
      title="About me"
      description="A shy and giggly boy, who likes spending time with family and friends, listen music and play sports."
    >
      <UIAboutWrapper>
        <UIAboutLeft>
          <NextImage
            src={'/images/home/smile_black_white_coffe.png'}
            layout="responsive"
            alt="Oriol Puig smiling"
            width={433}
            height={577}
          />
        </UIAboutLeft>
        <UIAboutRight className="about-me-description">
          <h3>Hello,</h3>
          <p>
            I am an experienced engineer focused on Frontend about more than
            nine years. Currently I am working at Adevinta Spain as a Frontend
            Engineer, helping them to growth Fotocasa and Habitaclia
            marketplaces.
          </p>
          <p>
            When {`it's `} not time to work, you would find me arround my family
            and friends or maybe playing football or padel. Also you could find
            me at some place in the world, because I love travel and learn from
            other cultures.
          </p>
        </UIAboutRight>
      </UIAboutWrapper>
    </Section>
  )
}

import {keyframes} from 'styled-components'

export const pulseAnimation = color => keyframes`
0% {
    color: inherit;
  }
  100% {
    color: var(${color});
  }
`
